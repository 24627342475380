import React, { useEffect, useState } from "react";
import { authHeader, history } from '../_helpers'

import Cookies from 'js-cookie'
import Header from '../components/Header/Header'
import { connect } from 'react-redux'
// import CookiesNew from 'universal-cookie';
import firebase from "./firebase";
import { viewSingleUserData } from '../actions/UserActions'

// const cookies = new CookiesNew();


export const AuthContext = React.createContext();
//const BaseUrl = `https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/webApi`
const BaseUrl = process.env.REACT_APP_CLOUD_FUNCTION_URL

const AuthProvider = ( props ) => {
  const { children, viewSingleUserData } = props
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(false);

  useEffect(  () => {

    let session = Cookies.get('__session')
    console.log('session are:: ',session)

    

    //if(currentUser === null){
      if(session === undefined){
        firebase.auth().signOut()
        localStorage.removeItem("customToken")

        localStorage.removeItem('token')
        localStorage.removeItem('currentUserRole')
        localStorage.removeItem('tenantId')
        localStorage.removeItem('pathname')
        localStorage.removeItem('uid')
        localStorage.removeItem('email')
        localStorage.removeItem('loginTime')
        localStorage.removeItem('quiz_id')

        history.push('/login')
      }

     firebase.auth().onAuthStateChanged( (user) => {
        console.log('currentUser:: ',user)
        let pathname = localStorage.getItem('pathname')
        console.log('pathname:: ',pathname)
        console.log('pathname afterrrrrrrr:: ',pathname)

        if(user !== null && user !== undefined && user !== ""){
            if(pathname !== "" && pathname !== null && pathname !== undefined){
                history.push(pathname)
            }
            
        }else{
            fetchData(user);
            setCurrentUser(user)
        }
        
      });
    
  }, []);

  async function fetchData(user) {
    
    let checkAuthUrl = `${BaseUrl}/checkAuth`
   // https://us-central1-recruitment-tracking-app-dev.cloudfunctions.net/webApi/checkAuth
    if(user === null){
      console.log('check session and verified')

      setPending(true)

      // for auth check

      let session = Cookies.get('__session')
      console.log('session:: ',session)
      if(session !== null && session !== "" && session !== undefined){
        try{
          let auth = firebase.auth()
          auth.tenantId = Cookies.get('tenantId');

          const authResponse = await fetch(checkAuthUrl,{
            method: 'POST',
            credentials: 'include',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': 'http://localhost:3000',
              'Access-Control-Allow-Credentials': '*',
              "Cookie": Cookies.get('__session')
            },
            body: JSON.stringify({"__session": Cookies.get('__session'), "tenantId":  Cookies.get('tenantId')} ),
          });

          if (!authResponse.ok) {
            console.log('fffff')
            //throw new Error(`Error! status: ${authResponse.status}`);
            return
          }

          const AuthResult = await authResponse.json();
          console.log('AuthResult are:: ',AuthResult)
          localStorage.setItem('customToken',AuthResult.customToken)

          let loginResponse = await firebase.auth().signInWithCustomToken(AuthResult.customToken) 
          console.log('loginResponse are:: ',loginResponse)

          const token = loginResponse.user.refreshToken
          const uid = loginResponse.user.uid
          const email = loginResponse.user.email
        //   const tenantId = loginResponse.user.tenantId
    
          
          localStorage.setItem('token', token)
          localStorage.setItem('email', email)
          localStorage.setItem('uid', uid)
          localStorage.setItem('tenantId', Cookies.get('tenantId'))
          let currentDate = new Date()
          localStorage.setItem('loginTime', currentDate)
          //viewSingleUserData(uid)
          history.push('/dashboard')
          setPending(false)

        }catch(error){
          console.log('errrorrr: ',error)
          history.push('/login')
          setPending(false)
        }
      }else{
        setPending(false)
        history.push('/login')
      }

      
    }else{
      setPending(false)
      history.push('/login')
    }

  }

  console.log('currentUser are:: ',currentUser)
  if(pending){
    return (
    // <>Loading...</>
      <>
        <div className="loading">Loading&#8230;</div>
      </>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      
      {children}
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    viewSingleUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)