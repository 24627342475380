import { GET_TENANT_ERROR, GET_TENANT_REQUEST, GET_TENANT_SUCCESS } from '../constants/TenantConstant'

const initialState = {
    loading: false,
    tenant: [],
    error: ''
}

const Tenant = (state = initialState, action) => {
    switch(action.type){
        case GET_TENANT_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_TENANT_SUCCESS:
            return{
                ...state,
                loading: false,
                tenant: action.tenant
            }
        case GET_TENANT_ERROR:
            return{
                error: action.err
            }
        default:
            return state
    }
}

export default Tenant