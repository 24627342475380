import "firebase/auth"

import { ADD_QUIZ_CATEGORY_ERROR, ADD_QUIZ_CATEGORY_REQUEST, ADD_QUIZ_CATEGORY_SUCCESS, CURRENT_QUIZ_CATEGORY_REQUEST, CURRENT_QUIZ_CATEGORY_SUCCESS, DELETE_QUIZ_CATEGORY_REQUEST, DELETE_QUIZ_CATEGORY_SUCCESS, GET_ALL_CATEGORY_NAME_ERROR, GET_ALL_CATEGORY_NAME_REQUEST, GET_ALL_CATEGORY_NAME_SUCCESS, GET_QUIZ_CATEGORY_LIST_ERROR, GET_QUIZ_CATEGORY_LIST_REQUEST, GET_QUIZ_CATEGORY_LIST_SUCCESS, UPDATE_QUIZ_CATEGORY_ERROR, UPDATE_QUIZ_CATEGORY_REQUEST, UPDATE_QUIZ_CATEGORY_SUCCESS, VIEW_SINGLE_QUIZ_CATEGORY_REQUEST, VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS } from '../constants/QuizCategoryConstants'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getData, getcount } from './PaginationWithSearch'

import { HirmeDBPATH } from '../components/docs/helperData'
import _ from 'lodash'
import { db } from '../components/firebase'
import firebase from '../components/firebase'
import { history } from '../_helpers'

let tenantId = localStorage.getItem('tenantId')

async function getList(){
    let array = []
    let categoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    let categoryList = db.collection(categoryRef)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage){
    const totalnodes = await getcount(searchTerm, "quiz_category")
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "quiz_category")
}

function* getQuizCategoryList(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage)
        yield put({ type: GET_QUIZ_CATEGORY_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_QUIZ_CATEGORY_LIST_ERROR, err })
    }
}

function addData(data){
    let categoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    return firebase.firestore().collection(categoryRef).add(data)
    .then(response => response)
}

function setvalue(data, obj){
    const { name, created_at, updated_at, created_by, modified_by, status, isDeleted } = data
    obj['status'] = status.value
    obj['created_at'] = created_at
    obj['created_by'] = created_by
    obj['name'] = name
    obj['updated_at'] = updated_at
    obj['modified_by'] = modified_by
    obj['isDeleted'] = isDeleted
    return obj
}

function* addQuizCategoryData(data){
    try{
        let obj = {}
        setvalue(data.data, obj)
        yield call(addData, obj)
        yield put({ type: ADD_QUIZ_CATEGORY_SUCCESS })
        history.push('/quizcategoryList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_QUIZ_CATEGORY_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteQuizCategoryData(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true

    let categoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    firebase.firestore().collection(categoryRef).doc(id).update(newList)
    yield put({ type: DELETE_QUIZ_CATEGORY_SUCCESS, id })
}

function viewData(id){
    let categoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    return firebase.firestore().collection(categoryRef).doc(id).get()
    .then(response => response)
}

function setLabel(data, obj){
    if(data.status === "" || data.status === undefined){
        obj.status['label'] = ''
        obj.status['value'] = ''
    }else if(data.status !== ""){
        obj.status['label'] = data.status
        obj.status['value'] = data.status
    }
}

function* viewSingleQuizCategoryData(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleCategory = value.data()
    let obj = {
        status: {}, 'name': singleCategory.name, 'isDeleted': singleCategory.isDeleted,
        'created_at': singleCategory.created_at, 'updated_at': singleCategory.updated_at,
        'created_by': singleCategory.created_by, 'modified_by': singleCategory.modified_by
    }
    setLabel(singleCategory, obj)
    localStorage.setItem('quizCategory_id', id)
    yield put({ type: VIEW_SINGLE_QUIZ_CATEGORY_SUCCESS, obj })
}

function updateData (obj){
    const id = localStorage.getItem('quizCategory_id')
    let categoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    return firebase.firestore().collection(categoryRef).doc(id).update(obj)
}

function* updateQuizCategoryData(data){
    try{
        let obj = {}
        setvalue(data.data, obj)
        yield call(updateData, obj)
        yield put({ type: UPDATE_QUIZ_CATEGORY_SUCCESS })
        history.push('/quizcategoryList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_QUIZ_CATEGORY_ERROR, err })
    }
}

function* getCurrentQuizCategoryData(){
    localStorage.removeItem('quizCategory_id')
    yield put({ type: CURRENT_QUIZ_CATEGORY_SUCCESS })
}

function* getAllCategoryNameData(){
    try{
        let array = [], list
        const newList = yield call(getList)
        if(!_.isEmpty(newList)){
            list = _.filter(newList, ['isDeleted', false])
            list.map(data => {
                array.push({ 'label': data.name, 'value': data.name, 'id': data.id })
            })
        }
        yield put({ type: GET_ALL_CATEGORY_NAME_SUCCESS, list, array })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_ALL_CATEGORY_NAME_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_QUIZ_CATEGORY_LIST_REQUEST, getQuizCategoryList)
      yield takeLatest(ADD_QUIZ_CATEGORY_REQUEST, addQuizCategoryData)
      yield takeLatest(DELETE_QUIZ_CATEGORY_REQUEST, deleteQuizCategoryData)
      yield takeLatest(VIEW_SINGLE_QUIZ_CATEGORY_REQUEST, viewSingleQuizCategoryData)
      yield takeLatest(UPDATE_QUIZ_CATEGORY_REQUEST, updateQuizCategoryData)
      yield takeLatest(CURRENT_QUIZ_CATEGORY_REQUEST, getCurrentQuizCategoryData)
      yield takeLatest(GET_ALL_CATEGORY_NAME_REQUEST, getAllCategoryNameData)
    }
    return {watcher}
}