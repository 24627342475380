import { Button, Confirm, Grid, Icon, Input, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import Select, {components} from 'react-select'
import { authHeader, history } from '../../../_helpers'
import { deleteQuizQuestion, getFilteredQuestionList, getQuizQuestionList } from '../../../actions/QuizQuestionActions'
import { featuredValue, question_level, question_type } from '../../docs/quizData'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../../constants/HelperConstants'

import Header from '../../Header/Header'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import ReactLoading from "react-loading"
import _ from 'lodash'
import classnames from 'classnames'
import { connect } from 'react-redux'
import createClass from "create-react-class"
import { getAllCategoryName } from '../../../actions/QuizCategoryActions'
import { getTagList } from '../../../actions/TagActions'

const QuestionsList = (props) => {
    let pathname = props.location.pathname
    const { getQuizQuestionList, deleteQuizQuestion, loading, error, count, quizQuestionList, getAllCategoryName, categoryValue, getFilteredQuestionList, getTagList, tagList, tagListForFilter } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")
    const [category, setCategory] = useState("")
    const [questionType, setQuestionType] = useState("")
    const [level, setLevel] = useState("")
    const [featured, setFeatured] = useState("")
    const [categoryvalue, setCategoryvalue] = useState("")
    const [featuredvalue, setFeaturedValue] = useState("")
    const [selectTag, setSelectTag] = useState("")

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        if(!_.isEmpty(category) || !_.isEmpty(questionType) || !_.isEmpty(level) || !_.isEmpty(featured) || !_.isEmpty(selectTag)){
            getFilteredQuestionList(category, questionType.value, level.value, featured, activePage, searchTerm, selectTag)
        }
        else{
                getQuizQuestionList(activePage, searchTerm, tagListForFilter)
            }
        getAllCategoryName()
        getTagList()
        
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //         if(!_.isEmpty(category) || !_.isEmpty(questionType) || !_.isEmpty(level) || !_.isEmpty(featured) || !_.isEmpty(selectTag)){
        //             getFilteredQuestionList(category, questionType.value, level.value, featured, activePage, searchTerm, selectTag)
        //         }
        //         else{
        //             getQuizQuestionList(activePage, searchTerm, tagListForFilter)
        //         }
        //         getAllCategoryName()
        //         getTagList()
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
    }, [pathname, getQuizQuestionList, activePage, searchTerm, getAllCategoryName, getFilteredQuestionList, getTagList])
    //console.log('quizQuestionList:: ',quizQuestionList)
    //console.log('getTagList hereee:: ',JSON.stringify(tagListForFilter))
    // let tagArr = [];
    // if(tagList !== "" && tagList !== undefined){
    //     _.forEach(tagList, function(item,inde){
    //         console.log('itemmm:: ',item)
    //         tagArr.push({value:item.name, label:item.name, id:item.id})
    //     })
    // }
    // console.log('tagArrtagArr:: ',tagArr)

    //console.log('quizQuestionList::: ',JSON.stringify(quizQuestionList))
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const deleteQuizQuestionData = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Question?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteQuestion(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteQuestion = (id) => {
        deleteQuizQuestion(id)
        setOpen(false)
    }

    const getTableBody = () => {
        const regex = /(<([^>]+)>)/ig
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            quizQuestionList.map((data, index) => {

                let tags = [];
                    if (data && data.tags && data.tags.length > 0) {
                        _.forEach(data.tags, function (item, idx) {
                            tags.push(item.tag_name)
                            tags.join(', ')
                        })
                    }
                return(
                    <Table.Row key={data.id} className="form-data">
                        <Table.Cell> {serialNo + index} </Table.Cell>
                        <Table.Cell> {data.question.replace(regex, '')} </Table.Cell>
                        <Table.Cell> {data.question_category.name} </Table.Cell>
                        <Table.Cell> {data.question_type} </Table.Cell>
                        <Table.Cell> {data.level} </Table.Cell>
                        <Table.Cell> 
                            {/* {tags.toString()} */} 
                            {data.tags && data.tags.length && data.tags.map((item,index) => {
                                //console.log('tags name:: ',item)
                                let text = _.truncate(item.name, {
                                    'length': 12,
                                    'separator': /,? +/
                                  });

                                  //console.log('textt:: ',text)
                                return (
                                    <div key={index} className="d-flex">
                                        <button className="btn btn-sm tagBtn">{item.tag_name ? item.tag_name : '--'}</button>
                                    </div>
                                )
                            })}
                        </Table.Cell>
                        <Table.Cell> { String(data.featured) } </Table.Cell>
                        <Table.Cell> {data.status} </Table.Cell>
                        <Table.Cell>
                            <Link to={{ pathname: `/editquizquestion/${data.id}`}}>
                                <Button icon className="edit-btn">
                                    <Icon name='edit' />
                                </Button>
                            </Link>
                            <Button icon onClick={() => deleteQuizQuestionData(data.id)} className="delete-btn">
                                <Icon name='trash' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Question </Table.HeaderCell>
                        <Table.HeaderCell> Category </Table.HeaderCell>
                        <Table.HeaderCell> Type </Table.HeaderCell>
                        <Table.HeaderCell> Level </Table.HeaderCell>
                        <Table.HeaderCell> Tags </Table.HeaderCell>
                        <Table.HeaderCell> Featured </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getTableBody()}
                    {deleteValue === false ? confirmDelete(currentUserId) : null}
                </Table.Body>
            </Table>
        )
    }

    const filterChange = (value, name) => {
        if(name === "Category"){
            setCategory([value.id])
            setCategoryvalue(value)
            getFilteredQuestionList([value.id], questionType.value, level.value, featured, 1, searchTerm, selectTag)
        }
        if(name === "questionType"){
            setQuestionType(value)
            getFilteredQuestionList(category, value.value, level.value, featured, 1, searchTerm, selectTag)
        }
        if(name === "Level"){
            setLevel(value)
            getFilteredQuestionList(category, questionType.value, value.value, featured, 1, searchTerm, selectTag)
        }
        if(name === "Featured"){
            let feature
            setFeaturedValue(value)
            if(value.value === "true"){
                feature = true
                setFeatured(true)
            }
            else{
                feature = false
                setFeatured(false)
            }
            getFilteredQuestionList(category, questionType.value, level.value, feature, 1, searchTerm, selectTag)
        }
        if(name === "tag"){
            console.log('tag select here:: ',value)
            setSelectTag(value)
            console.log('select tag:: ',selectTag)
            getFilteredQuestionList(category, questionType.value, level.value, featured, 1, searchTerm, value)
        }
        setActivePage(1)
    }

    const reset = () => {
        setCategory('')
        setCategoryvalue('')
        setQuestionType('')
        setLevel('')
        setFeaturedValue('')
        setFeatured('')
        setSelectTag('')
        getQuizQuestionList(activePage, searchTerm)
    }

    const Option = createClass({
        render() {
          return (
            <div>
              <components.Option {...this.props}>
                <input
                  type="checkbox"
                  checked={this.props.isSelected}
                  onChange={e => null}
                />{" "}
                <label>{this.props.value} </label>
              </components.Option>
            </div>
          )
        }
      })
  
      const MultiValue = props => {
          return (
            <components.MultiValue {...props}>
              <span>{props.data.label}</span>
            </components.MultiValue>
          )
        }


    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <Button className="reset-filter" color="grey" onClick={reset}>Reset Filter</Button> 
                <div>
                    <Input type="text"
                           placeholder="Enter level , type or tag"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} 
                        />
                    <Grid>
                        <Grid.Row className="filter-dropdown-row">
                            <Grid.Column width={3} className="filter-dropdown-column">
                                <Select options={categoryValue}
                                        placeholder="Select Category"
                                        value={categoryvalue}
                                        onChange={(value) => filterChange(value, "Category")} />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter-dropdown-column">
                                <Select options={question_type}
                                        placeholder="Select Type"
                                        value={questionType}
                                        onChange={(value) => filterChange(value, "questionType")} />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter-dropdown-column">
                                <Select options={question_level}
                                        placeholder="Select Level"
                                        value={level}
                                        onChange={(value) => filterChange(value, "Level")} />
                            </Grid.Column>
                            <Grid.Column width={3} className="filter-dropdown-column">
                                <Select options={featuredValue}
                                        placeholder="Select Featured"
                                        value={featuredvalue}
                                        onChange={(value) => filterChange(value, "Featured")} />
                            </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row className="filter-dropdown-row">
                        
                            Or
                        </Grid.Row>
                        <Grid.Row className="filter-dropdown-row">
                        
                            <Grid.Column width={6} className="filter-dropdown-column">
                                <Select options={tagListForFilter}
                                        placeholder="Select Tags"
                                        components={{ Option, MultiValue }}
                                        isMulti={false}
                                        value={selectTag}
                                        onChange={(value) => filterChange(value, "tag")} />
                            </Grid.Column>


                            
                        </Grid.Row> */}
                    </Grid>
                    <Grid.Column width={4}>
                                <Link to="/addquizquestion">
                                    <Button primary floated='right' className="add-btn filter-btn">
                                        <Icon name="plus" />
                                        Add Quiz Question
                                    </Button>
                                </Link>
                            </Grid.Column>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                <div className="questions-container">
                    {quizQuestionList !== undefined ? quizQuestionList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                </div>
                
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    quizQuestionList: state.quizQuestionReducer.quizQuestionList,
    loading: state.quizQuestionReducer.loading,
    error: state.quizQuestionReducer.error,
    count: state.quizQuestionReducer.count,
    categoryValue: state.quizCategoryReducer.categoryValue,
    tagList: state.tagReducer.tagList,
    tagListForFilter: state.tagReducer.tagListForFilter
})

const mapDispatchToProps = {
    getQuizQuestionList,
    deleteQuizQuestion,
    getAllCategoryName,
    getFilteredQuestionList,
    getTagList
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsList)