import '../scss/styles.scss'
import '../scss/form.scss'

import { Redirect, Route, Router } from 'react-router-dom'

import AddCategory from '../components/category/AddCategory'
import AddCourse from '../components/courses/AddCourse'
import AddCourseDetails from '../components/courses/coursesDetails/AddCourseDetails'
import AddDisabilityType from '../components/disability/AddDisabilityType'
import AddDiscipline from '../components/discipline/AddDiscipline'
import AddEducation from '../components/education/AddEducation'
import AddExam from '../components/exams/AddExam'
import AddQuiz from './quiz/quizes/AddQuiz'
import AddQuizCategory from './quiz/Category/AddQuizCategory'
import AddQuizQuestion from './quiz/Questions/AddQuizQuestion'
import AddUser from '../components/users/AddUser'
import Analytics from './analytics/Analytics'
import AuthProvider from "./Auth";
import CategoryList from '../components/category/CategoryList'
import CourseContainer from '../components/courses/CourseContainer'
import Dashboard from '../components/dashboard/Dashboard'
import DisabilityList from '../components/disability/DisabilityList'
import DisciplineContainer from '../components/discipline/DisciplineContainer'
import EducationContainer from '../components/education/EducationContainer'
import ExamContainer from '../components/exams/ExamContainer'
import Header from '../components/Header/Header'
import LoginPage from '../components/LoginPage/LoginPage'
import Profile from '../components/Profile/Profile'
import QuestionsList from './quiz/Questions/QuestionsList'
import QuizCategoryList from './quiz/Category/QuizCategoryList'
import QuizesList from './quiz/quizes/QuizesList'
import React from 'react'
import ResetPassword from '../components/Password/ResetPassword'
import Signup from '../components/Signup/Signup'
import SubjectContainer from '../components/subjects/SubjectContainer'
import TagContainer from '../components/quiz/Tags/TagContainer'
import UserList from '../components/users/UserList'
import { history } from '../_helpers'

const App = () => {
  let currentDate = new Date()
  let oldDate = localStorage.getItem('loginTime')
  let oldDateObj = new Date(oldDate)
  let difference = currentDate - oldDateObj
  var minutesDifference = Math.floor(difference/1000/60)
  difference -= minutesDifference*1000*60
  if(minutesDifference >= 30 || oldDate === null || oldDate === undefined){
    localStorage.removeItem('token')
  }
  else{
    localStorage.setItem('loginTime', currentDate)
  }
  return(
    <AuthProvider>
      
      <Router history={history}>
        <div className="App">
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={Signup} />
          <Route path="/exams" component={ExamContainer} />
          <Route path="/addexam" component={AddExam} />
          <Route path="/editexam/:id" component={AddExam} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/profile" component={Profile} />
          <Route path="/categoryList" component={CategoryList} />
          <Route path="/addcategory" component={AddCategory} />
          <Route path="/editcategory/:id" component={AddCategory} />
          <Route path="/disabilityList" component={DisabilityList} />
          <Route path="/adddisability" component={AddDisabilityType} />
          <Route path="/editdisability/:id" component={AddDisabilityType} />
          <Route path="/courseList" component={CourseContainer} />
          <Route path="/addcourse" component={AddCourse} />
          <Route path="/editcourse/:id" component={AddCourse} />
          <Route path="/discipline" component={DisciplineContainer} />
          <Route path="/adddiscipline" component={AddDiscipline} />
          <Route path="/editdiscipline/:id" component={AddDiscipline} />
          <Route path="/addcourseinformation/:id" component={AddCourseDetails} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/users" component={UserList} />
          <Route path="/adduser" component={AddUser} />
          <Route path="/edituser/:id" component={AddUser} />
          <Route path="/educationalQualificationList" component={EducationContainer} />
          <Route path="/addeducation" component={AddEducation} />
          <Route path="/editeducation/:id" component={AddEducation} />
          <Route path="/subjects" component={SubjectContainer} />
          <Route path="/tags" component={TagContainer} />
          <Route path="/quizcategoryList" component={QuizCategoryList} />
          <Route path="/addquizcategory" component={AddQuizCategory} />
          <Route path="/editquizcategory/:id" component={AddQuizCategory} />
          <Route path="/quizquestionList" component={QuestionsList} />
          <Route path="/addquizquestion" component={AddQuizQuestion} />
          <Route path="/editquizquestion/:id" component={AddQuizQuestion} />
          <Route path="/quizesList" component={QuizesList} />
          <Route path="/addquiz" component={AddQuiz} />
          <Route path="/editquiz/:id" component={AddQuiz} />
          <Route path="/analytics" component={Analytics} />
        </div>
      </Router>
    </AuthProvider>
  )
}

export default App
