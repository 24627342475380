import { HirmeDBPATH } from '../components/docs/helperData'
import _ from 'lodash'
import { db } from '../components/firebase'
import { pageSize } from '../constants/HelperConstants'
import { take } from 'lodash'

export const filterBy  = (term) => {
    const escapeRegExp = (str) =>
    str.replace('[-A-Z0-9+&@#\/%?=~_|!:,.;]')

    const re = new RegExp(escapeRegExp(term), 'i')
    return person => {
        for (let prop in person) {
        if (!person.hasOwnProperty(prop)) {
            continue;
        }
        if (re.test(person[prop])) {
            return true;
        }
        }
        return false;        
    }
}

export const paginate = (array, searchTerm, collectionName, key, obj) => {
    // console.log('array:: ',JSON.stringify(array));
    // console.log('array length:: ',array.length);
    let tagArr = []
    if(collectionName === "quiz_questions" || key === 'tag_search'){
        _.filter(obj, function (o) {
            if (_.startsWith(_.trim(_.lowerCase(o.value)), _.lowerCase(searchTerm))) {
                tagArr.push(o.id)
            }
        
        })
    }
    let newArray = []
     _.filter(array, function(o){
        if(collectionName === "users"){
            if(_.startsWith(_.trim(_.lowerCase(o.first_name)), _.lowerCase(searchTerm)) || _.startsWith(_.trim(_.lowerCase(o.email)), _.lowerCase(searchTerm))){
                newArray.push(o)
            }
        }
        else if(collectionName === "disability_type" || collectionName === "educational_qualification" || collectionName === "courses" || collectionName === "discipline" || collectionName === "quiz_category" || collectionName === "quizzes"){
            if(_.startsWith(_.trim(_.lowerCase(o.name)), _.lowerCase(searchTerm))){
                newArray.push(o)
            }
        }
        else if(collectionName === "quiz_questions" || key === 'tag_search'){
            if(_.startsWith(_.trim(_.lowerCase(o.level)), _.lowerCase(searchTerm)) || _.startsWith(_.trim(_.lowerCase(o.question_type)), _.lowerCase(searchTerm)) || matchTag(o)){
                newArray.push(o)
            }

        }
    })
    function matchTag(o){
     let obj = o.question_tags.some(r => tagArr.includes(r));
     //console.log('objff ', obj);
     return obj
    }
    console.log('new array:: ',newArray)
    return newArray
}

export const searchItem = async (data, searchTerm, newData, activePage, demo, collectionName, key, obj) => {
    let array = []
    await data.get()
    .then(snapshot => {
        if (snapshot.empty) {
            return;
        }
        snapshot.forEach(doc => {
            array.push({ ...doc.data(), id: doc.id })
        })
    })
    if(array.length !== 0){
        var value = ""
        value = paginate(array, searchTerm, collectionName, key, obj)
        // const value = sortBy(array.filter(filterBy(searchTerm)), [field])
        if(activePage === 1){
            const item = take(value, pageSize)
            item.map(val => {
                return newData.push(val)
            })
        }
        else if(activePage > 1){
            if(demo  === true){
                const items = paginate(value, searchTerm, collectionName, key, obj)
                let getIndex = ((activePage) * pageSize) - pageSize
                _.forEach(items, function(item, index){
                    if(index >= getIndex && newData.length !== pageSize){
                        newData.push(item)
                    }
                })
                // const items = paginate(value, pageSize, activePage)
                // items.map(item => {
                //     return newData.push(item)
                // })
            }
            return newData
        }
    }
}

export const getcount = async (searchTerm, collectionDBName, key, obj) => {
    console.log('collectionDBName in get Count are:: ',collectionDBName)
    let tenantId = localStorage.getItem('tenantId')
    let collectionName = `${HirmeDBPATH}/${tenantId}/${collectionDBName}`

    console.log('collectionName in get Count are:: ',collectionName)
    let array = [], newArray = [], newArrayy = [], list
    if(collectionDBName === "users"){
        list = db.collection(collectionName).where("isDeleted", "==", key).orderBy('created_at', "desc")
    }
    else if(collectionDBName === "educational_qualification" || collectionDBName === "disability_type" || collectionDBName === "courses"){
        list = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_date_time')
    }
    else if(collectionDBName === "discipline"){
        list = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at')
    }
    else if(collectionDBName === "quiz_category" || collectionDBName === "quiz_questions" || collectionDBName === "quizzes"){
        if(key === undefined){
            list = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else if(key === "employee"){
            list = db.collection(collectionName).where('settings.allowed_user', '==', 'Employee').where("status", "==", "Published").where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else if(key === 'tag_search'){
            console.log('tag_search heree')
            list = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else{
            console.log('elseeee')
            const { category, type, level, featured } = obj
            list = db.collection(collectionName)
            if(category !== null && !_.isEmpty(category)){
                list = list.where('question_category.id','in', category)
            }
            if(type !== null && !_.isEmpty(type)){
                list = list.where('question_type', '==', type)
            }
            if(level !== null && !_.isEmpty(level)){
                list = list.where('level', '==', level)
            }
            if(featured !== null && featured !== ''){
                list = list.where('featured', '==', featured)
            }
            list = list.where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
    }
    if(searchTerm === "" || searchTerm === undefined){
        await list.get()
        .then(snapshot => {
            if (snapshot.empty) {
              return;
            }
            snapshot.forEach(doc => {
                newArrayy.push({...doc.data(), id: doc.id})
            })
        })
        return newArrayy
    }
    else{
        await list.get()
        .then(snapshot => {
            if (snapshot.empty) {
              return;
            }
            snapshot.forEach(doc => {
                newArray.push({...doc.data(), id: doc.id})
            })
        })
        if(newArray.length !== 0){
            // const value = newArray.filter(filterBy(searchTerm))
            const value = paginate(newArray, searchTerm, collectionName, key, obj)
            value.map(val => {
                return array.push(val.id)
            })
            return array
        }
    }
}

export const getData = async (activePage, newData, searchTerm, collectionDBName, key, obj) => {
    console.log('collectionDBName in get Data are:: ',collectionDBName)
    let tenantId = localStorage.getItem('tenantId')
    let collectionName = `${HirmeDBPATH}/${tenantId}/${collectionDBName}`
    var lastIndex, data
    if(collectionDBName === "users"){
        data = db.collection(collectionName).where("isDeleted", "==", key).orderBy('created_at', "desc")
    }
    else if(collectionDBName === "educational_qualification" || collectionDBName === "disability_type"  || collectionDBName === "courses"){
        data = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_date_time', 'desc')
    }
    else if(collectionDBName === "discipline"){
        data = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at',"desc")
    }
    else if(collectionDBName === "quiz_category"|| collectionDBName === "quiz_questions" || collectionDBName === "quizzes"){
        if(key === undefined){
            data = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else if(key === "employee"){
            data = db.collection(collectionName).where('settings.allowed_user', '==', 'Employee').where("status", "==", "Published").where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else if(key === 'tag_search'){
            console.log('tag_search get data')
            data = db.collection(collectionName).where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
        else{
            const { category, type, level, featured } = obj
            data = db.collection(collectionName)
            if(category !== null && !_.isEmpty(category)){
                data = data.where('question_category.id','in', category)
            }
            if(type !== null && !_.isEmpty(type)){
                data = data.where('question_type', '==', type)
            }
            if(level !== null && !_.isEmpty(level)){
                data = data.where('level', '==', level)
            }
            if(featured !== null && featured !== ''){
                data = data.where('featured', '==', featured)
            }
            data = data.where("isDeleted", "==", false).orderBy('created_at', 'desc')
        }
    }
    if(activePage === 1){
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, newData, activePage, false, collectionName, key, obj)
        }
        else{
            await data.limit(pageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return;
                } 
                snapshot.forEach(doc => {
                    if(collectionName === "users" || collectionName === "discipline" || collectionName === "quiz_category" || collectionName === "quiz_questions" || collectionName === "quizzes"){
                        lastIndex = doc.data().created_at
                    }
                    else if(collectionName === "educational_qualification" || collectionName === "disability_type" || collectionName === "courses"){
                        lastIndex = doc.data().created_date_time
                    }
                    newData.push({ ...doc.data(), id:doc.id })
                })
            })
            .catch(err => {
                throw err
            })
        }
    } 
    else if(activePage > 1){
        const temPageSize = pageSize * (activePage - 1)
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, newData, activePage, false, collectionName, key, obj)
        }
        else{
            await data.limit(temPageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return
                } 
                snapshot.forEach(doc => {
                    if(collectionName === "users" || collectionName === "discipline" || collectionName === "quiz_category" || collectionName === "quiz_questions" || collectionName === "quizzes"){
                        lastIndex = doc.data().created_at
                    }
                    else if(collectionName === "educational_qualification"|| collectionName === "disability_type" || collectionName === "courses"){
                        lastIndex = doc.data().created_date_time
                    }
                })
            })
            .catch(err => {
            })
        }
        if(searchTerm !== ""){
            await searchItem(data, searchTerm, newData, activePage, true, collectionName, key, obj)
        }
        else{
            await data.startAfter(lastIndex).limit(pageSize).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    return;
                } 
                snapshot.forEach(doc => {
                    newData.push({ ...doc.data(), id:doc.id })
                })
            })
            .catch(err => {
            })
        }
    }
        return newData
}
