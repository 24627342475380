import React, { useEffect } from 'react'
import { addQuizCategory, currentQuizCategory, updateQuizCategory, viewSingleQuizCategory } from '../../../actions/QuizCategoryActions'
import { authHeader, history } from '../../../_helpers'

import FormContainer from './FormContainer'
import Header from '../../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading"
import { connect } from 'react-redux'
import { db } from '../../firebase'

const AddQuizCategory = (props) => {
    const { pathname } = props.location
    const { currentQuizCategory, addQuizCategory, viewSingleQuizCategory, updateQuizCategory, error, loading } = props

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
        if(props.match && props.match.params && props.match.params.id){
            const newQuizCategory = props.match.params.id
            viewSingleQuizCategory(newQuizCategory)
        }
        else{
            currentQuizCategory()
        }
    },[pathname, props.match, viewSingleQuizCategory, currentQuizCategory])

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let quizCategory_id = localStorage.getItem('quizCategory_id')
        if(quizCategory_id === null || quizCategory_id === ""){
            data['created_at'] = new Date()
            data['created_by'] = uid
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            data['isDeleted'] = false
            addQuizCategory(data)
        }
        else{
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            updateQuizCategory(data)
        }
    }

    return(
        <div className="wrapper">
            <div className="container">
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/quizcategoryList"> <Icon name="arrow left" /> Back to Category List </Link>
                <FormContainer onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.quizCategoryReducer.loading,
    error: state.quizCategoryReducer.error
})

const mapDispatchToProps = {
    currentQuizCategory,
    addQuizCategory,
    viewSingleQuizCategory,
    updateQuizCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuizCategory)