import { Card, Divider, Image } from 'semantic-ui-react'
import React, { useEffect } from 'react'
import { authHeader, history } from '../../_helpers'

import Header from '../Header/Header'

const Dashboard = (props) => {
    let pathname = props.location.pathname

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
    })

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                {/* <Card.Group className="dashboard-content">
                    <Card href='/exams'>
                        <Image src='/images/exams.jpeg' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header> Exams </Card.Header>
                        </Card.Content>
                    </Card>
                    <Card href='/disabilityList'>
                        <Image src='/images/disability.jpeg' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header> Disabilities </Card.Header>
                        </Card.Content>
                    </Card>
                    <Card href='/courseList'>
                        <Image src='/images/courses.png' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header> Courses </Card.Header>
                        </Card.Content>
                    </Card>
                </Card.Group> */}
                <Divider hidden />
                <Card.Group className="dashboard-content">
                    {/* <Card href='/discipline'>
                        <Image src='/images/discipline.jpeg' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header> Discipline </Card.Header>
                        </Card.Content>
                    </Card> */}
                    <Card href='/quizesList'>
                        <Image src='/images/quiz.png' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header> Quiz </Card.Header>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </div>
        </div>
    )
}

export default Dashboard