import "firebase/auth"

import { GET_TENANT_ERROR, GET_TENANT_REQUEST, GET_TENANT_SUCCESS } from '../constants/TenantConstant'
import { HirmeDBPATH, HirmeTenantDBPATH } from '../components/docs/helperData'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getData, getcount } from './PaginationWithSearch'

import Cookies from 'js-cookie'
import _ from 'lodash'
import { db } from '../components/firebase'
import firebase from '../components/firebase'
import { history } from '../_helpers'

async function getTenant(id){
  

  const colRef = firebase.firestore().collection(HirmeTenantDBPATH).doc(id);


  let result = [];
  await colRef.get().then((docSnap) => {
    if(docSnap.exists){
        result.push(docSnap.data())
    }
  })

  console.log('result are:: ',result)
  return result
}


function* getTenantById (values) {
    let { tenantId } = values
  try{
      //let tenantId = localStorage.getItem('tenantId')
    let tenant = yield call(getTenant, tenantId)
    yield put({ type: GET_TENANT_SUCCESS, tenant })
    
  }
  catch(error){
    console.log('errrorrr:: ',error)
    const err = error.message
    yield put({ type: GET_TENANT_ERROR, err })
  }
}


export default () => {
    function * watcher() {
      yield takeLatest(GET_TENANT_REQUEST, getTenantById)
    }
    return {watcher}
}

