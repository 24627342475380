import "firebase/auth"

import { ADD_EDUCATION_ERROR, ADD_EDUCATION_REQUEST, ADD_EDUCATION_SUCCESS, CURRENT_EDUCATION_REQUEST, CURRENT_EDUCATION_SUCCESS, DELETE_EDUCATION_DATA_REQUEST, DELETE_EDUCATION_DATA_SUCCESS, GET_EDUCATIONAL_LIST_ERROR, GET_EDUCATIONAL_LIST_REQUEST, GET_EDUCATIONAL_LIST_SUCCESS, UPDATE_EDUCATION_ERROR, UPDATE_EDUCATION_REQUEST, UPDATE_EDUCATION_SUCCESS, VIEW_SINGLE_EDUCATION_REQUEST, VIEW_SINGLE_EDUCATION_SUCCESS } from '../constants/EducationConstants'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getData, getcount } from './PaginationWithSearch'

import { HirmeDBPATH } from '../components/docs/helperData'
import _ from 'lodash'
import { db } from '../components/firebase'
import firebase from '../components/firebase'
import { history } from '../_helpers'

let tenantId = localStorage.getItem('tenantId')

async function getList(){
    let array = []
    let educationalRef = `${HirmeDBPATH}/${tenantId}/educational_qualification`
    let categoryList = db.collection(educationalRef).where("isDeleted", "==", false)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage){
    const totalnodes = await getcount(searchTerm, "educational_qualification")
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "educational_qualification")
}

function* getEducationList(values){
    try{
        const { activePage, searchTerm } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage)
        yield put({ type: GET_EDUCATIONAL_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_EDUCATIONAL_LIST_ERROR, err })
    }
}

function addData(data){
    let educationalRef = `${HirmeDBPATH}/${tenantId}/educational_qualification`
    return firebase.firestore().collection(educationalRef).add(data)
    .then(response => response)
}

function* addEducationData(data){
    try{
        yield call(addData, data.data)
        yield put({ type: ADD_EDUCATION_SUCCESS })
        history.push('/educationalQualificationList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_EDUCATION_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteEducation(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true

    let educationalRef = `${HirmeDBPATH}/${tenantId}/educational_qualification`

    firebase.firestore().collection(educationalRef).doc(id).update(newList)
    yield put({ type: DELETE_EDUCATION_DATA_SUCCESS, id })
}

function viewData(id){
    let educationalRef = `${HirmeDBPATH}/${tenantId}/educational_qualification`
    return firebase.firestore().collection(educationalRef).doc(id).get()
    .then(response => response)
}

function* viewSingleEducation(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleEducation = value.data()
    yield put({ type: VIEW_SINGLE_EDUCATION_SUCCESS, singleEducation })
}

function updateData (value, id){
    let educationalRef = `${HirmeDBPATH}/${tenantId}/educational_qualification`
    return firebase.firestore().collection(educationalRef).doc(id).update(value)
}

function* updateEducation(data){
    try{
        const { id, value } = data
        yield call(updateData, value, id)
        yield put({ type: UPDATE_EDUCATION_SUCCESS })
        history.push('/educationalQualificationList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_EDUCATION_ERROR, err })
    }
}

function* getCurrentEducation(){
    yield put({ type: CURRENT_EDUCATION_SUCCESS })
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_EDUCATIONAL_LIST_REQUEST, getEducationList)
      yield takeLatest(ADD_EDUCATION_REQUEST, addEducationData)
      yield takeLatest(DELETE_EDUCATION_DATA_REQUEST, deleteEducation)
      yield takeLatest(VIEW_SINGLE_EDUCATION_REQUEST, viewSingleEducation)
      yield takeLatest(UPDATE_EDUCATION_REQUEST, updateEducation)
      yield takeLatest(CURRENT_EDUCATION_REQUEST, getCurrentEducation)
    }
    return {watcher}
}