import { Button, Confirm, Icon, Input, Table } from 'semantic-ui-react'
import React, { useEffect, useState }  from 'react'
import { addTagData, deleteTagData, getTagList, tagSearch, updateTagData } from '../../../actions/TagActions'
import { authHeader, history } from '../../../_helpers'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../../constants/HelperConstants'

import Header from '../../Header/Header'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import ReactLoading from "react-loading"
import _ from 'lodash'
import { connect } from 'react-redux'

const TagContainer = (props) => {
    let pathname = props.location.pathname
    const { getTagList, tagList, loading, error, deleteTagData, addTagData, updateTagData, tagSearch } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [deleteTagId, setdelettagId] = useState(null)
    const [editing, setEditing] = useState(false)
    const [currentId, setCurrentId] = useState('')
    const [newValue, setNewValue] = useState('')
    const [name, setName] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [nameError, setNameError] = useState('')
    const [updateNameError, setUpdateNameError] = useState('')
    const [searchTerm, setSearchTerm] = useState("")

    let offset = (activePage - 1) * itemsCountPerPage
    console.log('tagList are:: ',tagList)
    let paginatedItems = tagList && tagList.length > 0 && tagList.slice(offset).slice(0, itemsCountPerPage)

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        getTagList()
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //         getTagList()
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
    }, [getTagList, pathname])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const deleteTag = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setdelettagId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Tag?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteTag(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteTag = (id) => {
        deleteTagData(id)
        setOpen(false)
    }

    const editName = (id, name) => {
        setEditing(true)
        setCurrentId(id)
        setNewValue(name)
    }

    const nameChange = (event) => {
        const target = event.target
        const value = target.value
        setNewValue(value)
        setUpdateNameError('')
    }

    const updateTag = () => {
        let Uid = localStorage.getItem('uid')
        if(!_.isEmpty(newValue)){
            let data = {
                name: newValue,
                updated_at: new Date(),
                modified_by: Uid
            }
            updateTagData(currentId, data)
            setEditing(false)
            setUpdateNameError('')
        }
        else{
            setUpdateNameError('Please Enter Tag Name')
        }
    }

    const cancelNameChange = () => {
        setEditing(false)
        setCurrentId('')
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            <Table.Body>
                {paginatedItems.map((data,index) => {
                    return(
                        <Table.Row key={data.id} className="form-data">
                            <Table.Cell> {serialNo + index} </Table.Cell>
                            <Table.Cell> 
                                {editing && currentId === data.id  ? 
                                <>
                                    <Input type="string"
                                        value={newValue}
                                        placeholder="Enter Name"
                                        onChange={(event) => nameChange(event)} />
                                    {updateNameError !== '' ? <h5 className="errorMsg"> {updateNameError} </h5> : null} 
                                </>
                                : data.name} 
                            </Table.Cell>
                            <Table.Cell>
                                <Button icon className="edit-btn" onClick={() => editName(data.id, data.name)}>
                                    <Icon name='edit' />
                                </Button>
                                <Button icon onClick={() => deleteTag(data.id)} className="delete-btn">
                                    <Icon name='trash' />
                                </Button>
                            </Table.Cell>
                            <Table.Cell>
                                {editing && currentId === data.id ?
                                <div>
                                    <Button primary onClick={() => updateTag()}> Update </Button>
                                    <Button onClick={() => cancelNameChange()} className="reset-button"> Cancel </Button>
                                </div>: null}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Name </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {getTableBody()}
                {deleteValue === false ? confirmDelete(deleteTagId) : null}
            </Table>
        )
    }

    const handleChange = (event) => {
        const target = event.target
        const value = target.value
        setName(value)
        setNameError('')
    }

    const handleSubmit = () => {
        let Uid = localStorage.getItem('uid')
        if(!_.isEmpty(name)){
            let data = {
                created_at: new Date(),
                created_by: Uid,
                name: name,
                updated_at: new Date(),
                modified_by: Uid,
                isDeleted: false
            }
            addTagData(data)
            setName('')
            setNameError('')
        }
        else{
            setNameError('Please Enter Tag Name')
        }
    }
    //console.log('tagList:: ',JSON.stringify(tagList))
    const onEnterPress = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault()
            if(!_.isEmpty(name)){
                handleSubmit()
                setNameError('')
            }
            else{
                setNameError('Please Enter Tag Name')
            }
        }
    }

    const handleTagChange = (event) => {
        const target =  event.target
        const { value } = target
        //console.log('value:: ',value)
        setSearchTerm(value)
        tagSearch(value)
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div className="Tag-Search">
                <Input type="text"
                           placeholder="Search tag"
                           className="search-box"
                           onChange={handleTagChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} 
                        />
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                <div className="add-subject">
                    <Input placeholder="Enter Tag Name" type="text" value={name} onChange={(event) => handleChange(event)} onKeyDown={onEnterPress} />
                    {nameError !== '' ? <h5 className="errorMsg"> {nameError} </h5> : null}
                    <Button primary onClick={handleSubmit}> Submit </Button>
                </div>
                {tagList !== undefined ? tagList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                {error === "" && tagList.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={tagList.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                : error}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tagList: state.tagReducer.tagList,
    loading: state.tagReducer.loading,
    error: state.tagReducer.error
})

const mapDispatchToProps = {
    getTagList,
    deleteTagData,
    updateTagData,
    addTagData,
    tagSearch
}

export default connect(mapStateToProps, mapDispatchToProps)(TagContainer)