import { Divider, Dropdown, Header, Icon, Image, Menu, Popup } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { authHeader, history } from '../../_helpers'

import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import firebase from "../firebase";
import { getTenantById } from '../../actions/TenantAction'
import { logoutUser } from '../../actions/UserActions'
import opennaukriLogo from '../../assets/opennaukriLogo.png'

const HeaderContainer = (props) => {
    const { getTenantById, tenant } =  props
    let currentUserRole = localStorage.getItem('currentUserRole')
    const [isOpen, setIsOpen] = useState(false)
    console.log(' tenant are:: ',tenant)
    useEffect(() => {
        console.log('header use effect')
        let tenantId = Cookies.get('tenantId')
        console.log('tenantId are:: ',tenantId)
        if(tenantId !== undefined){
           getTenantById(tenantId)
        }
        
    },[])
    const handleClose = () => {
        setIsOpen(!isOpen)
    }

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const logout = () => {
        props.logoutUser()

        // localStorage.removeItem('token')
        // localStorage.removeItem('currentUserRole')
        // localStorage.removeItem('tenantId')
        // localStorage.removeItem('pathname')
        // localStorage.removeItem('uid')
        // localStorage.removeItem('email')
        // localStorage.removeItem('loginTime')
        // localStorage.removeItem('quiz_id')
        // localStorage.removeItem('customToken')
        // Cookies.remove('__session', {domain: "localhost"});
        // Cookies.remove('tenantId', {domain: "localhost"});
        // firebase.auth().signOut().then(() => {
        //     history.push('/login')
        // }).catch((error) => {
        //     // An error happened.
        //     history.push('/login')
        // });
    }

    return(
        <div className="header-container">
            <Header as='h3' block>
                <div className="sub-heading">
                    <div className="img-logo">
                        <Link to="/dashboard">
                            <Image src={tenant && tenant.length && tenant[0].companyLogo} />
                        </Link>
                    </div>
                    <div className="list-container">
                        {/* <Link to="/exams">
                            Exams
                        </Link>
                        <Link to="/disabilityList">
                            Disabilities
                        </Link>
                        <Link to="/courseList">
                            Courses
                        </Link>
                        <Link to="/discipline">
                            Discipline
                        </Link> */}
                        {/* <Link to="/users">
                            Users
                        </Link> */}
                         {/* <Link to="/educationalQualificationList">
                            Education
                        </Link> */}
                        {/*<Link to="/subjects">
                            Subjects
                        </Link>
                        <Link to="/analytics">
                            Analytics
                        </Link> */}
                        <Menu>
                            <Menu.Menu>
                                <Dropdown item text='Quiz'>
                                <Dropdown.Menu>
                                    <Menu.Item as={ Link } to='/tags'>
                                        Tags
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizcategoryList'>
                                        Category
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizquestionList'>
                                        Questions
                                    </Menu.Item>
                                    <Menu.Item as={ Link } to='/quizesList'>
                                        Quizzes
                                    </Menu.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>              
                        </Menu>
                        
                        
                        
                        
                    </div>
                    
                </div>
                <div>
                <Header floated='right' className='profile-icon'>
                    <Popup trigger={<Icon name="user outline" size="large" />}
                        on='click'
                        open={isOpen}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        position='bottom center'>
                        <Popup.Content> 
                            {/* <Link to="/resetPassword">
                                <Icon name="key" /> Change Password
                            </Link> */}
                            <Divider hidden />
                            <Link to="/profile">
                                <Icon name="user" /> Profile
                            </Link>
                            <Divider hidden />
                            <div className="logout-btn" onClick={logout}>
                                <Icon name="share square outline" /> 
                                <span>Logout</span>
                            </div>
                        </Popup.Content>
                    </Popup>
                </Header>
                </div>
            </Header>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tenant: state.tenantReducer.tenant
})

const mapDispatchToProps = {
    logoutUser,
    getTenantById
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)