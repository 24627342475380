import { Button, Confirm, Icon, Input, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { authHeader, history } from '../../../_helpers'
import { deleteQuizCategory, getQuizCategoryList } from '../../../actions/QuizCategoryActions'
import { itemsCountPerPage, pageRangeDisplayed, pageSize } from '../../../constants/HelperConstants'

import Header from '../../Header/Header'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import ReactLoading from "react-loading"
import { connect } from 'react-redux'

const QuizCategoryList = (props) => {
    let pathname = props.location.pathname
    const { getQuizCategoryList, quizCategoryList, deleteQuizCategory, loading, error, count } = props
    const [deleteValue, setDeleteValue] = useState(true)
    const [open, setOpen] = useState(true)
    const [currentUserId, setCurrentUserId] = useState(null)
    const [activePage, setActivePage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        getQuizCategoryList(activePage, searchTerm)
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //         getQuizCategoryList(activePage, searchTerm)
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
    }, [getQuizCategoryList, pathname, activePage, searchTerm])

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    }

    const handleChange = (event) => {
        const target =  event.target
        const { value } = target
        setSearchTerm(value)
    }

    const deleteQuizCategoryData = (id) => {
        setDeleteValue(false)
        setOpen(true)
        setCurrentUserId(id)
    }

    const confirmDelete = (id) => {
        return (
            <Confirm open={open}
                    content='Are you sure want to delete this Quiz Category?'
                    onCancel={close}
                    className="delete-popup"
                    cancelButton='No, cancel it!'
                    confirmButton="Yes, delete it!"
                    onConfirm={() => renderDeleteCategory(id)} />
        )
    }

    const close = () => {
        setOpen(false)
        setDeleteValue(true)
    }

    const renderDeleteCategory = (id) => {
        deleteQuizCategory(id)
        setOpen(false)
    }

    const getTableBody = () => {
        let serialNo = ((activePage - 1) * pageSize) + 1
        return(
            quizCategoryList.map((data, index) => {
                return(
                    <Table.Row key={data.id} className="form-data">
                        <Table.Cell> {serialNo + index} </Table.Cell>
                        <Table.Cell> {data.name} </Table.Cell>
                        <Table.Cell> {data.status} </Table.Cell>
                        <Table.Cell>
                            <Link to={{ pathname: `/editquizcategory/${data.id}`}}>
                                <Button icon className="edit-btn">
                                    <Icon name='edit' />
                                </Button>
                            </Link>
                            <Button icon onClick={() => deleteQuizCategoryData(data.id)} className="delete-btn">
                                <Icon name='trash' />
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        )
    }

    const getTable = () => {
        return(
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> S.No </Table.HeaderCell>
                        <Table.HeaderCell> Category name </Table.HeaderCell>
                        <Table.HeaderCell> Status </Table.HeaderCell>
                        <Table.HeaderCell> Actions </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getTableBody()}
                    {deleteValue === false ? confirmDelete(currentUserId) : null}
                </Table.Body>
            </Table>
        )
    }

    return(
        <div className="wrapper">
            <div className="container">
                <Header />
                <Link className="link-container exam-content" to="/dashboard">
                    <Icon name="arrow left" />
                    Back to Dashboard
                </Link>
                <div>
                    <Input type="text"
                           placeholder="Enter text"
                           className="search-box"
                           onChange={handleChange}
                           action={{
                            color: "blue",
                            icon: "search"
                          }}
                           value={searchTerm} />
                    <Link to="/addquizcategory">
                        <Button primary floated='right' className="add-btn">
                            <Icon name="plus" />
                            Add Quiz Category
                        </Button>
                    </Link>
                </div>
                {loading ? <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                {error !== "" ? <h3 className="errorMsg"> {error} </h3> : null}
                {quizCategoryList !== undefined ? quizCategoryList.length !== 0 ? getTable() : <h3> No Data </h3> :null}
                <div>
                    {error === "" && count.length > pageSize ?
                    <Pagination activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={count.length}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange} />
                    : error}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    quizCategoryList: state.quizCategoryReducer.quizCategoryList,
    loading: state.quizCategoryReducer.loading,
    error: state.quizCategoryReducer.error,
    count: state.quizCategoryReducer.count
})

const mapDispatchToProps = {
    getQuizCategoryList,
    deleteQuizCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizCategoryList)