import "firebase/auth"

import { ADD_QUIZ_ERROR, ADD_QUIZ_REQUEST, ADD_QUIZ_SUCCESS, CURRENT_QUIZ_REQUEST, CURRENT_QUIZ_SUCCESS, DELETE_BANNER_IMAGE_ERROR, DELETE_BANNER_IMAGE_REQUEST, DELETE_BANNER_IMAGE_SUCCESS, DELETE_QUIZ_REQUEST, DELETE_QUIZ_SUCCESS, GENERATE_RANDOM_QUESTIONS_ERROR, GENERATE_RANDOM_QUESTIONS_REQUEST, GENERATE_RANDOM_QUESTIONS_SUCCESS, GET_ALL_QUESTIONS_ERROR, GET_ALL_QUESTIONS_REQUEST, GET_ALL_QUESTIONS_SUCCESS, GET_QUESTIONS_ERROR, GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS, GET_QUIZ_LIST_ERROR, GET_QUIZ_LIST_REQUEST, GET_QUIZ_LIST_SUCCESS, GET_SAVED_QUESTIONS_REQUEST, GET_SAVED_QUESTIONS_SUCCESS, MAKE_QUESTION_SET_REQUEST, MAKE_QUESTION_SET_SUCCESS, UPDATE_QUIZ_ERROR, UPDATE_QUIZ_REQUEST, UPDATE_QUIZ_SUCCESS, VIEW_SINGLE_QUIZ_REQUEST, VIEW_SINGLE_QUIZ_SUCCESS } from '../constants/QuizConstants'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getData, getcount } from './PaginationWithSearch'

import { HirmeDBPATH } from '../components/docs/helperData'
import _ from 'lodash'
import { db } from '../components/firebase'
import { deleteImageFromStorage } from './DeleteImage'
import firebase from '../components/firebase'
import { generateUniqueId } from './UniqueId'
import { history } from '../_helpers'

let tenantId = localStorage.getItem('tenantId')

async function getList(){
    let array = []

    let quizRef = `${HirmeDBPATH}/${tenantId}/quizzes`
    let list = db.collection(quizRef)
    await list.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

async function getTotalNodes(count, searchTerm, list, activePage, key){
    const totalnodes = await getcount(searchTerm, "quizzes", key)
    if(totalnodes.length === undefined){
        totalnodes.docs.map(doc => {
            return count.push(doc.id)
        })
    }
    else{
        totalnodes.map(id => {
            return count.push(id)
        })
    }
    await getData(activePage, list, searchTerm, "quizzes", key)
}

function* getQuizList(values){
    try{
        const { activePage, searchTerm, key } = values
        let list = [], count = []
        yield call(getTotalNodes, count, searchTerm, list, activePage, key)
        yield put({ type: GET_QUIZ_LIST_SUCCESS, list, count })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_QUIZ_LIST_ERROR, err })
    }
}

function* currentQuizData(){
    localStorage.removeItem('quiz_id')
    localStorage.removeItem('banner_image')
    yield put({ type: CURRENT_QUIZ_SUCCESS })
}

async function getQuestionsList(question_category, question_type, level, featured){
    let array = [];
    let quizQuestionRef = `${HirmeDBPATH}/${tenantId}/quiz_questions`
    let questionlist = db.collection(quizQuestionRef)
    if(question_category !== null && question_category.length !== 0){
        questionlist = questionlist.where('question_category.id','in', question_category)
    }
    if(question_type !== null && !_.isEmpty(question_type)){
        questionlist = questionlist.where('question_type', '==', question_type)
    }
    if(level !== null && !_.isEmpty(level)){
        questionlist = questionlist.where('level', '==', level)
    }
    if(featured !== null && !_.isEmpty(featured)){
        questionlist = questionlist.where('featured', '==', featured)
    }
    await questionlist.where("isDeleted", "==", false).where("status", "==", "Published").get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getQuestionsData(values){
    try{
        const { question_category, question_type, level, featured, tagValue } = values
        let list = yield call(getQuestionsList, question_category, question_type, level, featured)
        console.log('listtt :: ',list)
        console.log('tagValue in getQuestion data :: ',tagValue);
        console.log('question_category in get question data:: ',question_category)
        if(tagValue !== null && tagValue !== "" && tagValue !== undefined){
            list = _.filter(list, { question_tags : [tagValue] })
        }
        list.map(item => {
            item['isChecked'] = false
        })
        yield put({ type: GET_QUESTIONS_SUCCESS, list })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_QUESTIONS_ERROR, err })
    }
}

function addData(data){
    let quizRef = `${HirmeDBPATH}/${tenantId}/quizzes`
    return firebase.firestore().collection(quizRef).add(data)
    .then(response => response)
}

function setvalue(data, obj){
    let quiz_id = localStorage.getItem('quiz_id')
    let banner_image = localStorage.getItem('banner_image')
    const { created_at, updated_at, created_by, modified_by, isDeleted, name, category_name, level, featured, access_type, amount, description, required, minutes, number_of_questions, pausable, previous, quiz_stop, next, eligibility, question_type, questions, random_questions, allowed_user, isToggle, tag_name } = data
    console.log('category name:: ',category_name)
    console.log('tag_name:: ',tag_name)
    if(category_name === "" || category_name === undefined || category_name === null){
        obj['category_name'] = []
        obj['category_id'] = []
    }else if(category_name !== ""){
        const newVal = setValue(category_name)
        const newValue = setIdValue(category_name)
        obj['category_name'] = newVal
        obj['category_id'] = newValue
    }
    if(tag_name === "" || tag_name === undefined || tag_name === null){
        obj['tag_name'] = []
        obj['tag_id'] = []
    }else if(tag_name !== ""){
        const newVal = setValue(tag_name)
        const newValue = setIdValue(tag_name)
        obj['tag_name'] = newVal
        obj['tag_id'] = newValue
    }
    if(eligibility === "" || eligibility === undefined){
        obj.settings['eligibility'] = "All"
    }else if(eligibility !== ""){
        obj.settings['eligibility'] = eligibility.value
    }
    if(description === "" || description === undefined){  
    }else if(description !== ""){
        obj.settings['description'] = description
    }
    if(amount === "" || amount === undefined){  
        obj.settings['amount'] = 0
    }else if(amount !== ""){
        if(access_type.value === "Paid"){
            obj.settings['amount'] = _.toNumber(amount)
        }
        else{
            obj.settings['amount'] = 0
        }
    }
    if(level === "" || level === undefined || level === null){
        obj['level'] = ""
    }else if(level !== ""){
        obj['level'] = level.value
    }

    if(isToggle === "" || isToggle === undefined || isToggle === null){
        obj['status'] = "Unpublished"
    }else if(isToggle !== ""){
        if(isToggle == true){
            obj['status'] = "Published"
        }else{
            obj['status'] = "Unpublished"
        }
    }


    if(question_type === "" || question_type === undefined || question_type === null){
        obj['question_type'] = ""
    }else if(question_type !== ""){
        obj['question_type'] = question_type.value
    }
    if(featured === "" || featured === undefined || featured === null){
        obj['featured'] = ""
    }else if(featured !== ""){
        if(featured === "Yes"){
            obj['featured'] = true
        }
        else{
            obj['featured'] = false
        }
    }
    if(pausable === "" || pausable === undefined){
        obj.settings['pausable'] = false
    }else if(pausable !== ""){
        obj.settings['pausable'] = pausable
    }
    if(previous === "" || previous === undefined){
        obj.settings['previous'] = false
    }else if(previous !== ""){
        obj.settings['previous'] = previous
    }
    if(quiz_stop === "" || quiz_stop === undefined){
        obj.settings['quiz_stop'] = false
    }else if(quiz_stop !== ""){
        obj.settings['quiz_stop'] = quiz_stop
    }
    if(next === "" || next === undefined){
        obj.settings['next'] = false
    }else if(next !== ""){
        obj.settings['next'] = next
    }
    if(questions === "" || questions === undefined){
        obj['questions'] = questions
    }else if(questions !== ""){
        if(quiz_id === null || quiz_id === ""){
            obj['questions'] = questions
        }
        else{
            obj['questions'] = questions
        }
    }
    if(random_questions === "Generate Question from frontend"){
        obj['random_questions'] = true
    }else{
        obj['random_questions'] = false
    }
    if(required === "" || required === undefined){
        obj.settings.duration['required'] = false
        obj.settings.duration['minutes'] = 0
    }else if(required !== ""){
        obj.settings.duration['required'] = required
        if(required){
            obj.settings.duration['minutes'] = minutes.value
        }
        else{
            obj.settings.duration['minutes'] = 0
        }
    }
    obj.settings['access_type'] = access_type.value
    obj.settings['allowed_user'] = allowed_user.value
    obj.settings['number_of_questions'] = number_of_questions
    obj['created_at'] = created_at
    obj['created_by'] = created_by
    obj['name'] = name
    obj['updated_at'] = updated_at
    obj['modified_by'] = modified_by
    obj['isDeleted'] = isDeleted
    obj['banner_image'] = banner_image
    return obj
}

function setValue(value){
    console.log('valuee: ',value)
    let array = []
    value.map((data) => {
        return array.push(data.value)
    })
    return array
}

function setIdValue(value){
    let array = []
    value.map((data) => {
        return array.push(data.id)
    })
    return array
}

async function uploadImages(data, img_name, folderName, subFolderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${subFolderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function* addQuizData(data){
    try{
        let obj = {
            settings: {
                duration: {}
            }
        }
        const { cropImageData } = data
        if(!_.isEmpty(cropImageData) && cropImageData !== undefined){
            const { file, name, folderName, subFolderName } = cropImageData
            yield call(uploadImages, file, name, folderName, subFolderName)
        }
        setvalue(data.data, obj)
        console.log('obj final:: ',obj)
        yield call(addData, obj)
        yield put({ type: ADD_QUIZ_SUCCESS })
        localStorage.removeItem('banner_image')
        history.push('/quizesList')
    }
    catch(error){
        const err = error.message
        yield put({ type: ADD_QUIZ_ERROR, err })
    }
}

function getNewList(list, id){
    let obj = {}
    _.forEach(list, function(value){
        if(value.id === id){
            obj = value
        }
    })
    return obj
}

function* deleteQuizData(data){
    const { id } = data
    const list = yield call(getList)
    const newList = yield call(getNewList, list, id)
    newList.isDeleted = true
    let quizRef = `${HirmeDBPATH}/${tenantId}/quizzes`
    firebase.firestore().collection(quizRef).doc(id).update(newList)
    yield put({ type: DELETE_QUIZ_SUCCESS, id })
}

async function getAllQuestionsList(question_category, question_type, level, featured){
    let array = [];
    let quizQuestionRef = `${HirmeDBPATH}/${tenantId}/quiz_questions`
    let questionlist = db.collection(quizQuestionRef)
    console.log('question_category aree::: ',question_category)
    if(question_category !== null && question_category !== undefined && question_category.length !== 0){
        questionlist = questionlist.where('question_category.id','in', question_category)
    }
    if(question_type !== null && !_.isEmpty(question_type)){
        questionlist = questionlist.where('question_type', '==', question_type)
    }
    if(level !== null && !_.isEmpty(level)){
        questionlist = questionlist.where('level', '==', level)
    }
    if(featured !== null && !_.isEmpty(featured)){
        questionlist = questionlist.where('featured', '==', featured)
    }
    await questionlist.where("isDeleted", "==", false).where("status", "==", "Published").orderBy('created_at', 'desc').get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getAllQuestionsData(values){
    try{
        const { question_category, question_type, level, featured, tagValue } = values
        let list = yield call(getAllQuestionsList, question_category, question_type, level, featured)
        console.log('listt get all question:: ',list)
        console.log('tagValue in all question:: ',tagValue)
        if(tagValue !== null && tagValue !== "" && tagValue !== undefined){
            let idArr = [];
            _.forEach(tagValue, function(item,index){
                idArr.push(item.id)
            })
            list = _.filter(list, { question_tags : idArr })
        }
        
        list.map(item => {
            item['isChecked'] = false
        })
        yield put({ type: GET_ALL_QUESTIONS_SUCCESS, list })
    }
    catch(error){
        console.log('errore areeE::: ',error)
        const err = error.message
        yield put({ type: GET_ALL_QUESTIONS_ERROR, err })
    }
}

function* getSavedQuestionsData(values){
    const { questions } = values
    let questionId = []
    if(questions !== undefined){
        questions.map(item => {
            questionId.push(item.id)
        })
    }
    yield put({ type: GET_SAVED_QUESTIONS_SUCCESS, questions, questionId })
}

function viewData(id){
    let quizRef = `${HirmeDBPATH}/${tenantId}/quizzes`
    return firebase.firestore().collection(quizRef).doc(id).get()
    .then(response => response)
}

async function getCategoryList(){
    let array = []
    let quizCategoryRef = `${HirmeDBPATH}/${tenantId}/quiz_category`
    let categoryList = db.collection(quizCategoryRef)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function setLabel(data, obj, categoryList, list, tagList){
    if(data.settings.eligibility === "" || data.settings.eligibility === undefined){
        obj.eligibility = ""
    }else if(data.settings.eligibility !== ""){
        if(data.settings.eligibility === "All"){
            obj.eligibility['label'] = data.settings.eligibility
            obj.eligibility['value'] = data.settings.eligibility
        }
        else{
            obj.eligibility['label'] = data.settings.eligibility
            obj.eligibility['value'] = data.settings.eligibility
        }
    }
    if(data.settings.access_type === "" || data.settings.access_type === undefined){
        obj.access_type = ""
    }else if(data.settings.access_type !== ""){
        obj.access_type['label'] = data.settings.access_type
        obj.access_type['value'] = data.settings.access_type
    }
    if(data.settings.allowed_user === "" || data.settings.allowed_user === undefined){
        obj.access_type = ""
    }else if(data.settings.allowed_user !== ""){
        obj.allowed_user['label'] = data.settings.allowed_user
        obj.allowed_user['value'] = data.settings.allowed_user
    }

    if(data.status === "" || data.status === undefined){
        obj.isToggle = false
    }else if(data.status !== ""){
        if(data.status === "Published"){
            obj.isToggle = true
        }else{
            obj.isToggle = false
        }
    }


    if(data.level === "" || data.level === undefined){
        obj.level = ""
    }else if(data.level !== ""){
        obj.level['label'] = data.level
        obj.level['value'] = data.level
    }
    if(data.question_type === "" || data.question_type === undefined){
        obj.question_type = ""
    }else if(data.question_type !== ""){
        obj.question_type['label'] = data.question_type
        obj.question_type['value'] = data.question_type
    }
    if(data.featured === "" || data.featured === undefined){
        obj['featured'] = ""
    }else if(data.featured !== ""){
        if(data.featured === false){
            obj['featured'] = "No"
        }else{
            obj['featured'] = "Yes"
        }
    }
    if(data.random_questions === "" || data.random_questions === undefined){
        obj.random_questions = ""
    }else if(data.random_questions !== ""){
        if(data.random_questions === false){
            obj.random_questions = "Generate Question from backend"
        }else{
            obj.random_questions = "Generate Question from frontend"
        }
    }
    if(data.settings.description === undefined){
        obj['description'] = ""
    }
    else{
        obj['description'] = data.settings.description
    }
    if(data.settings.amount === undefined){
        obj['amount'] = ""
    }
    else{
        obj['amount'] = data.settings.amount
    }
    if(data.settings.duration.required === "" || data.settings.duration.required === undefined){
        obj.required = ""
    }else if(data.settings.required !== ""){
        obj.required = data.settings.duration.required
    }
    if(data.settings.duration.minutes === "" || data.settings.duration.minutes === undefined){
        obj.minutes = ""
    }else if(data.settings.minutes !== ""){
        obj.minutes['label'] = data.settings.duration.minutes
        obj.minutes['value'] = data.settings.duration.minutes
    }
    if(data.questions === "" || data.questions === undefined){
    }else if(data.questions !== ""){
        list.map(item => {
            data.questions.map(id => {
                if(id === item.id){
                    item['isChecked'] = true
                    obj.questions.push(item)
                }
            })
        })
    }
    if(data.category_name === "" || data.category_name === undefined){
    }else if(data.category_name !== ""){
        data.category_name.map(val => {
            categoryList.map(item => {
                if(item.name === val){
                    return obj.category_name.push({'label': item.name, 'value': item.name, 'id': item.id})
                }
            })
        })
    }
    if(data.tag_name === "" || data.tag_name === undefined){
    }else if(data.tag_name !== ""){
        data.tag_name.map(val => {
            tagList.map(item => {
                if(item.name === val){
                    return obj.tag_name.push({'label': item.name, 'value': item.name, 'id': item.id})
                }
            })
        })
    }
}

async function getTagList(){
    let array = []
    
    let quizTagRef = `${HirmeDBPATH}/${tenantId}/quiz_tags`
    let categoryList = db.collection(quizTagRef).where("isDeleted", "==", false).orderBy('created_at', 'desc')
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* viewSingleQuizData(data){
    const { id } = data
    let value = yield call(viewData, id)
    const singleQuiz = value.data()
    console.log('singleQuiz:: ',singleQuiz)
    let feature, category, questionType, level, bannerImage, tag
    if(singleQuiz.featured === undefined){
        feature = null
    }
    else{
        feature = singleQuiz.featured
    }
    if(singleQuiz.category_id.length === 0){
        category = null
    }
    else{
        category = singleQuiz.category_id
    }
    if(singleQuiz && singleQuiz.tag_id && singleQuiz.tag_id.length === 0){
        tag = null
    }
    else{
        tag = singleQuiz.tag_id
    }
    if(singleQuiz.question_type === undefined){
        questionType = null
    }
    else{
        questionType = singleQuiz.question_type
    }
    if(singleQuiz.level === undefined){
        level = null
    }
    else{
        level = singleQuiz.level
    }
    if(singleQuiz.banner_image === null){
        bannerImage = false
    }
    else{
        bannerImage = true
    }
    const list = yield call(getQuestionsList, category, questionType, level, feature)
    let obj = {
        'name': singleQuiz.name, 'category_name': [], 'tag_name': [], 'tag_id': singleQuiz.tag_id ? singleQuiz.tag_id : [], 'question_type':{}, 'level':{}, 'access_type':{}, 'allowed_user': {},
        'eligibility':{}, 'pausable':singleQuiz.settings.pausable,'quiz_stop':singleQuiz.settings.quiz_stop, 'next':singleQuiz.settings.next, 'previous': singleQuiz.settings.previous,
        'questions':[],'category_id':singleQuiz.category_id, 'random_questions': '', 'banner_image': singleQuiz.banner_image,
        'required': '', 'minutes':{}, 'number_of_questions': singleQuiz.settings.number_of_questions,
        'created_at': singleQuiz.created_at, 'created_by': singleQuiz.created_by, 'updated_at': singleQuiz.updated_at,
        'modified_by': singleQuiz.modified_by, 'isDeleted': singleQuiz.isDeleted, 'id': id, status: singleQuiz.status
    }
    if(singleQuiz.banner_image !== null){
        localStorage.setItem('banner_image', singleQuiz.banner_image)
    }
    const categoryList = yield call(getCategoryList)
    const tagList = yield call(getTagList)
    console.log('Tag List:: , ', tagList)
    setLabel(singleQuiz, obj, categoryList, list, tagList)
    console.log('obj:: ',obj)
    console.log('tag:: ',tag)
    localStorage.setItem('quiz_id', id)
    let questionId = []
    if(obj.questions !== undefined || obj.questions.length !== 0){
        obj.questions.map(item => {
            questionId.push(item.id)
        })
    }
    console.log('final quiz data:: ',obj)
    yield put({ type: VIEW_SINGLE_QUIZ_SUCCESS, obj, category, questionType, level, feature, questionId, bannerImage, tag })
}

function updateData (value){
    let id = localStorage.getItem('quiz_id')
    let quizRef = `${HirmeDBPATH}/${tenantId}/quizzes`
    return firebase.firestore().collection(quizRef).doc(id).update(value)
}

function* updateQuizData(data){
    try{
        let obj = {
            settings: {
                duration: {}
            }
        }
        const { cropImageData } = data
        if(!_.isEmpty(cropImageData) && cropImageData !== undefined){
            const { file, name, folderName, subFolderName } = cropImageData
            yield call(uploadImages, file, name, folderName, subFolderName)
        }
        setvalue(data.data, obj)
        yield call(updateData, obj)
        yield put({ type: UPDATE_QUIZ_SUCCESS })
        localStorage.removeItem('banner_image')
        history.push('/quizesList')
    }
    catch(error){
        const err = error.message
        yield put({ type: UPDATE_QUIZ_ERROR, err })
    }
}

function* makeQuestionSetData(values){
    const { list } = values
    yield put({ type: MAKE_QUESTION_SET_SUCCESS, list })
}

async function generateQuestions(question_category, question_type, level, featured, totalQuestions){
    let tenantId = localStorage.getItem("tenantId")
    let requestBody = {
        "question_category":question_category,
        "question_type":question_type,
        "level": level,
        "featured":featured,
        "question_number":totalQuestions,
        "tenantId": tenantId
    }
    let apiUrl = process.env.REACT_APP_RANDOM_API
    console.log('api url:: ',apiUrl)
    return await fetch(apiUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
        .then(response => response.json())
        .catch(err => { throw err })
}

function* generateQuestionsData(values){
    try{
        const { question_category, question_type, level, featured, totalQuestions } = values
        const list = yield call(generateQuestions, question_category, question_type, level, featured, totalQuestions)
        const { data } = list
        data.map(item => {
            item.isChecked = true
            return item
        })
        let questionId = []
        if(data !== undefined){
            data.map(item => {
                questionId.push(item.id)
            })
        }
        yield put({ type: GENERATE_RANDOM_QUESTIONS_SUCCESS, data, questionId })
    }
    catch(error){
        const err = error.message
        yield put({ type: GENERATE_RANDOM_QUESTIONS_ERROR, err })
    }
}

async function deleteImage(file_url) {
    try{
        await deleteImageFromStorage(file_url)
    } 
    catch (error){
        throw error
    }
}

function* DeleteBannerImageData(data){
    try{
        const { file_url, list } = data
        let obj = {
            settings: {
                duration: {}
            }
        }
        localStorage.removeItem('banner_image')
        setvalue(list, obj)
        yield call(updateData, obj)
        yield call(deleteImage, file_url)
        yield put({ type: DELETE_BANNER_IMAGE_SUCCESS })
    }
    catch(error){
        const err = error.message
        yield put({ type: DELETE_BANNER_IMAGE_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_QUIZ_LIST_REQUEST, getQuizList)
      yield takeLatest(ADD_QUIZ_REQUEST, addQuizData)
      yield takeLatest(DELETE_QUIZ_REQUEST, deleteQuizData)
      yield takeLatest(VIEW_SINGLE_QUIZ_REQUEST, viewSingleQuizData)
      yield takeLatest(UPDATE_QUIZ_REQUEST, updateQuizData)
      yield takeLatest(CURRENT_QUIZ_REQUEST, currentQuizData)
      yield takeLatest(GET_QUESTIONS_REQUEST, getQuestionsData)
      yield takeLatest(GET_ALL_QUESTIONS_REQUEST, getAllQuestionsData)
      yield takeLatest(GET_SAVED_QUESTIONS_REQUEST, getSavedQuestionsData)
      yield takeLatest(MAKE_QUESTION_SET_REQUEST, makeQuestionSetData)
      yield takeLatest(GENERATE_RANDOM_QUESTIONS_REQUEST, generateQuestionsData)
      yield takeLatest(DELETE_BANNER_IMAGE_REQUEST, DeleteBannerImageData)
    }
    return {watcher}
}