import "firebase/auth"

import { GET_EDUCATIONAL_DATA_ERROR, GET_EDUCATIONAL_DATA_REQUEST, GET_EDUCATIONAL_DATA_SUCCESS, GET_SUBJECT_ERROR, GET_SUBJECT_REQUEST, GET_SUBJECT_SUCCESS, UPLOAD_IMAGE_ERROR, UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS } from '../constants/HelperConstants'
import { call, put, takeLatest } from 'redux-saga/effects'

import { HirmeDBPATH } from '../components/docs/helperData'
import _ from 'lodash'
import { db } from '../components/firebase'
import firebase from '../components/firebase'
import { generateUniqueId } from './UniqueId'

let tenantId = localStorage.getItem('tenantId')

async function getData(){
    let array = []
    let educationArr = []
    let educationalRef = `${HirmeDBPATH}/${tenantId}/HighestQualification`
    let categoryList = db.collection(educationalRef)
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })

    //console.log('array are::: ',array)

    array[0].Qualifications
    .forEach((item, idx) => {
        //console.log('item are::: ',item)
        educationArr.push({label: item, value: item, name:item, display_name:item, created_by:"", created_date_time:"", modified_by:"", modified_date_time:""})
    })

   // console.log('educationArr are::: ',educationArr)
    
    return educationArr
}

function* getEducationData(){
    try{
        const educationallist = yield call(getData)
        _.forEach(educationallist, function(list){
            list['value'] = list.name
            list['label'] = list.display_name
        })
        yield put({ type: GET_EDUCATIONAL_DATA_SUCCESS, educationallist })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_EDUCATIONAL_DATA_ERROR, err })
    }
}

async function uploadImages(data, img_name, folderName){
    try{
        let name
        name = data.name
        let value = generateUniqueId()
        let newName=""
        newName = newName.concat(value, '_', name)
        var storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(`${folderName}/${newName}`)
        var uploadTask = await fileRef.put(data)
        var downloadURL = await uploadTask.ref.getDownloadURL()
        localStorage.setItem(img_name, downloadURL)
    }
    catch(error){
        throw error
    }
}

function* uploadImageData(data){
    try{
        const { name, folderName } = data
        yield call(uploadImages, data.data, name, folderName)
        yield put({ type: UPLOAD_IMAGE_SUCCESS })
    }
    catch(error){
        const err = error.message
        yield put({ type: UPLOAD_IMAGE_ERROR, err })
    }
}

async function getSubjectData(){
    let array = []
    let categoryList = db.collection("subjects")
    await categoryList.get()
    .then(snapshot => {
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach(doc => {
            array.push({...doc.data(), id: doc.id})
        })
    })
    return array
}

function* getSubjectList(){
    try{
        const subjectsList = yield call(getSubjectData)
        let array = []
        _.forEach(subjectsList, function(list){
            _.forEach(list.name, function(item){
                array.push({ 'label': item, 'value': item })
            })
        })
        yield put({ type: GET_SUBJECT_SUCCESS, array })
    }
    catch(error){
        const err = error.message
        yield put({ type: GET_SUBJECT_ERROR, err })
    }
}

export default () => {
    function * watcher() {
      yield takeLatest(GET_EDUCATIONAL_DATA_REQUEST, getEducationData)
      yield takeLatest(UPLOAD_IMAGE_REQUEST, uploadImageData)
      yield takeLatest(GET_SUBJECT_REQUEST, getSubjectList)
    }
    return {watcher}
}