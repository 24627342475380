import React, { useEffect, useState } from 'react'
import { addQuiz, currentQuiz, updateQuiz, viewSingleQuiz } from '../../../actions/QuizActions'
import { authHeader, history } from '../../../_helpers'

import FormContainer from './FormContainer'
import Header from '../../Header/Header'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactLoading from "react-loading"
import _ from 'lodash'
import { connect } from 'react-redux'
import { getEducationalData } from '../../../actions/helperActions'

const AddQuiz = (props) => {
    const { pathname } = props.location
    const { currentQuiz, addQuiz, viewSingleQuiz, updateQuiz, error, loading, getEducationalData, savedQuestions, questionSet, totalQuestions, allQuestionsList, randomQuestionvalue, cropImageData, uploadError } = props
    const [questionsError, setQuestionsError] = useState('')
    const [showError, setShowError] = useState(false)
    const minimumQuestion = 1
    const maximumQuestion = 30

    useEffect(() => {
        localStorage.setItem('pathname', pathname)
        getEducationalData()
        // if(authHeader){
        //     if(localStorage.getItem('token')){
        //         authHeader(pathname)
        //         getEducationalData()
        //     }
        //     else{
        //         history.push('/login') 
        //     }
        // }
        if(props.match && props.match.params && props.match.params.id){
            const newQuiz = props.match.params.id
            viewSingleQuiz(newQuiz)
        }
        else{
            currentQuiz()
        }
    },[pathname, props.match, , getEducationalData, currentQuiz, viewSingleQuiz])

    const changeMsg = (child) => {
        if(child === 'success'){
            setQuestionsError('')
        }
    }

    const removeError = (value) => {
        setShowError(value)
    }

    const handleSubmit = (data) => {
        let uid = localStorage.getItem('uid')
        let quiz_id = localStorage.getItem('quiz_id')
        console.log('data:: ',data)
        if(quiz_id === null || quiz_id === ""){
            data['created_at'] = new Date()
            data['created_by'] = uid
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            data['isDeleted'] = false
            data['questions'] = savedQuestions
            if(data.questions.length !== 0 && !randomQuestionvalue){
                if(data.questions.length < minimumQuestion){
                    setShowError(true)
                    setQuestionsError("Minimum 1 question should be added. Please select questions again")
                }
                else if(data.questions.length > maximumQuestion){
                    setShowError(true)
                    setQuestionsError("Maximum 30 question should be added. Please select questions again")
                }
                else{
                    if(_.toNumber(totalQuestions) !== questionSet.length){
                        setShowError(true)
                        setQuestionsError('Questions list should be equal to No of questions')
                    }
                    else if(uploadError){
                        setShowError(true)
                        setQuestionsError('Please click on upload button to upload image')
                    }
                    else{
                        setShowError(false)
                        setQuestionsError('')
                        addQuiz(data, cropImageData)
                    }
                }
            }
            else if(randomQuestionvalue){
                if(totalQuestions < minimumQuestion){
                    setShowError(true)
                    setQuestionsError("Minimum 1 question should be generated")
                }
                else if(totalQuestions > maximumQuestion){
                    setShowError(true)
                    setQuestionsError("Maximum 30 question can be generated")
                }
                else{
                    if(_.toNumber(totalQuestions) > allQuestionsList.length && allQuestionsList.length !== 0){
                        setShowError(true)
                        setQuestionsError(`Only ${allQuestionsList.length} Questions can be added based on filter added`)
                    }
                    else if(allQuestionsList.length === 0){
                        setShowError(true)
                        setQuestionsError('No Questions can be generated by adding this filter')
                    } 
                    else if(uploadError){
                        setShowError(true)
                        setQuestionsError('Please click on upload button to upload image')
                    }
                    else{
                        setShowError(false)
                        setQuestionsError('')
                        addQuiz(data, cropImageData)
                    }
                }
            }
            else{
                if(!randomQuestionvalue){
                    setShowError(true)
                    if(_.isEmpty(questionSet)){
                        setQuestionsError('Please generate questions')
                    }
                }
            }
        }
        else{
            data['updated_at'] = new Date()
            data['modified_by'] = uid
            data['questions'] = savedQuestions
            if(data.questions.length !== 0 && !randomQuestionvalue){
                if(data.questions.length < minimumQuestion){
                    setShowError(true)
                    setQuestionsError("Minimum 1 question should be added. Please select questions again")
                }
                else if(data.questions.length > maximumQuestion){
                    setShowError(true)
                    setQuestionsError("Maximum 30 question should be added. Please select questions again")
                }
                else{
                    if(_.toNumber(totalQuestions) !== questionSet.length){
                        setShowError(true)
                        setQuestionsError('Questions list should be equal to No of questions')
                    }
                    else if(uploadError){
                        setShowError(true)
                        setQuestionsError('Please click on upload button to upload image')
                    }
                    else{
                        setShowError(false)
                        setQuestionsError('')
                        updateQuiz(data, cropImageData)
                    }
                }
            }
            else if(randomQuestionvalue){
                if(totalQuestions < minimumQuestion){
                    setShowError(true)
                    setQuestionsError("Minimum 1 question should be generated")
                }
                else if(totalQuestions > maximumQuestion){
                    setShowError(true)
                    setQuestionsError("Maximum 30 question can be generated")
                }
                else{
                    if(_.toNumber(totalQuestions) > allQuestionsList.length && allQuestionsList.length !== 0){
                        setShowError(true)
                        setQuestionsError(`Only ${allQuestionsList.length} Questions can be added based on filter added`)
                    }
                    else if(allQuestionsList.length === 0){
                        setShowError(true)
                        setQuestionsError('No Questions can be generated by adding this filter')
                    } 
                    else if(uploadError){
                        setShowError(true)
                        setQuestionsError('Please click on upload button to upload image')
                    }
                    else{
                        setShowError(false)
                        setQuestionsError('')
                        updateQuiz(data, cropImageData)
                    }
                }
            }
            else{
                if(!randomQuestionvalue){
                    setShowError(true)
                    if(_.isEmpty(questionSet)){
                        setQuestionsError('Please generate questions')
                    }
                }
            }
        }
    }
    
    return(
        <div className="wrapper">
            <div className="container">
                {showError ? <h5 className="errorMsg"> {questionsError} </h5>: null}
                {error !== "" ? <h5 className="errorMsg"> {error} </h5>: null}
                {loading ?  <ReactLoading className="loader-container" type="spin" color="#007bff" height={30} width={30} /> : null}
                <Header />
                <Link className="link-container" to="/quizesList"> <Icon name="arrow left" /> Back to Quiz List </Link>
                <FormContainer onSubmit={handleSubmit} changeMsg={changeMsg} removeError={removeError} maximumQuestion={maximumQuestion} minimumQuestion={minimumQuestion} newQuiz={props.match.params.id} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.quizReducer.loading,
    error: state.quizReducer.error,
    savedQuestions: state.quizReducer.savedQuestions,
    questionSet: state.quizReducer.questionSet,
    totalQuestions: state.quizReducer.totalQuestions,
    allQuestionsList: state.quizReducer.allQuestionsList,
    randomQuestionvalue: state.quizReducer.randomQuestionvalue,
    cropImageData: state.quizReducer.cropImageData,
    uploadError: state.helperReducer.uploadError
})

const mapDispatchToProps = {
    currentQuiz,
    addQuiz,
    viewSingleQuiz,
    updateQuiz,
    getEducationalData
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuiz)